var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
// @ts-nocheck
import { css } from 'styled-components';
export var devices = {
    small: function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n            @media (min-width: 480px) {\n                ", ";\n            }\n        "], ["\n            @media (min-width: 480px) {\n                ", ";\n            }\n        "])), css.apply(void 0, args));
    },
    tablet: function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n            @media (min-width: 768px) {\n                ", ";\n            }\n        "], ["\n            @media (min-width: 768px) {\n                ", ";\n            }\n        "])), css.apply(void 0, args));
    },
    desktop: function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n            @media (min-width: 992px) {\n                ", ";\n            }\n        "], ["\n            @media (min-width: 992px) {\n                ", ";\n            }\n        "])), css.apply(void 0, args));
    },
    giant: function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n            @media (min-width: 1200px) {\n                ", ";\n            }\n        "], ["\n            @media (min-width: 1200px) {\n                ", ";\n            }\n        "])), css.apply(void 0, args));
    }
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
