var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { devices, GlobalStyle } from './styles';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: relative;\n    display: flex;\n    align-self: center;\n    align-items: center;\n    justify-content: center;\n"], ["\n    position: relative;\n    display: flex;\n    align-self: center;\n    align-items: center;\n    justify-content: center;\n"])));
var ParticlesContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    width: 70%;\n    height: 70%;\n    position: relative;\n    display: flex;\n    align-self: center;\n    align-items: center;\n    justify-content: center;\n    overflow: visible;\n    visibility: hidden;\n    ", ";\n"], ["\n    width: 70%;\n    height: 70%;\n    position: relative;\n    display: flex;\n    align-self: center;\n    align-items: center;\n    justify-content: center;\n    overflow: visible;\n    visibility: hidden;\n    ",
    ";\n"])), devices.desktop(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        visibility: visible;\n    "], ["\n        visibility: visible;\n    "]))));
export var Particles = function () { return (_jsxs(Container, { children: [_jsx(GlobalStyle, {}, void 0),
        _jsx(ParticlesContainer, { id: "particles-js" }, void 0)] }, void 0)); };
var templateObject_1, templateObject_2, templateObject_3;
