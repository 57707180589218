var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from 'styled-components';
import { routes } from './constants';
import { devices, theme } from './styles';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    paddingtop: 10px;\n    paddingbottom: 15px;\n    zindex: 100;\n    display: flex;\n    height: 80px;\n    alignitems: center;\n    justifycontent: flex-start;\n    background: white;\n"], ["\n    paddingtop: 10px;\n    paddingbottom: 15px;\n    zindex: 100;\n    display: flex;\n    height: 80px;\n    alignitems: center;\n    justifycontent: flex-start;\n    background: white;\n"])));
var FlexWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    flex-direction: column;\n    height: 90%;\n    margin: 0 auto;\n    ", ";\n"], ["\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    flex-direction: column;\n    height: 90%;\n    margin: 0 auto;\n    ",
    ";\n"])), devices.tablet(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        justifyContent: space-between;\n        flex-direction: row;\n    "], ["\n        justifyContent: space-between;\n        flex-direction: row;\n    "]))));
var StyledALink = styled.a(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    font-size: 16px;\n    padding: 2px 25px;\n    margin: 10px 0 10px 15px;\n    color: ", ";\n    transition: all 0.5s;\n    text-decoration: none;\n    text-transform: uppercase;\n    text-align: center;\n    border: 1px solid transparent;\n    background: transparent;\n    &:hover {\n        border: 1px solid ", ";\n        background: ", ";\n    }\n    ", ";\n    ", ";\n"], ["\n    font-size: 16px;\n    padding: 2px 25px;\n    margin: 10px 0 10px 15px;\n    color: ", ";\n    transition: all 0.5s;\n    text-decoration: none;\n    text-transform: uppercase;\n    text-align: center;\n    border: 1px solid transparent;\n    background: transparent;\n    &:hover {\n        border: 1px solid ", ";\n        background: ", ";\n    }\n    ",
    ";\n    ",
    ";\n"])), theme.primary, theme.primary, theme.secondary, devices.tablet(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n        font-size: 16px;\n        padding: 2px 25px;\n        justifyContent: space-between;\n        flex-direction: row;\n    "], ["\n        font-size: 16px;\n        padding: 2px 25px;\n        justifyContent: space-between;\n        flex-direction: row;\n    "]))), devices.desktop(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n        font-size: 20px;\n    "], ["\n        font-size: 20px;\n    "]))));
export var Header = function () { return (_jsxs(_Fragment, { children: [_jsx("title", { children: "Michael Wilson" }, void 0),
        _jsx(Wrapper, { children: _jsxs(FlexWrapper, { children: [_jsx(StyledALink, __assign({ href: routes.PROJECTS }, { children: "Projects" }), void 0),
                    _jsx(StyledALink, __assign({ href: routes.LINKEDIN }, { children: "LinkedIn" }), void 0),
                    _jsx(StyledALink, __assign({ href: routes.PHOTOGRAPHY }, { children: "Photography" }), void 0),
                    _jsx(StyledALink, __assign({ href: routes.VIDEOGRAPHY }, { children: "Videography" }), void 0),
                    _jsx(StyledALink, __assign({ href: routes.RESUME }, { children: "Resume" }), void 0),
                    _jsx(StyledALink, __assign({ href: routes.CONTACT }, { children: "Contact" }), void 0)] }, void 0) }, void 0)] }, void 0)); };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
