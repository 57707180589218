var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { devices, theme } from './styles';
var Container = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    display: flex;\n    align-items: left;\n    margin-top: 5px;\n    justify-content: left;\n    flex-direction: column;\n    width: 90%;\n    ", ";\n    ", ";\n    ", ";\n"], ["\n    display: flex;\n    align-items: left;\n    margin-top: 5px;\n    justify-content: left;\n    flex-direction: column;\n    width: 90%;\n    ", ";\n    ", ";\n    ", ";\n"])), devices.tablet(templateObject_1 || (templateObject_1 = __makeTemplateObject(["width: 70%;"], ["width: 70%;"]))), devices.desktop(templateObject_2 || (templateObject_2 = __makeTemplateObject(["width: 50%;"], ["width: 50%;"]))), devices.giant(templateObject_3 || (templateObject_3 = __makeTemplateObject(["width: 45%;"], ["width: 45%;"]))));
var TitleContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    display: flex;\n    justify-content: space-between;\n    flex-direction: row;\n"], ["\n    display: flex;\n    justify-content: space-between;\n    flex-direction: row;\n"])));
var Title = styled.h4(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    a {\n        &:hover {\n            text-decoration: underline;\n        }\n        text-decoration: none;\n        color: ", ";\n    }\n"], ["\n    a {\n        &:hover {\n            text-decoration: underline;\n        }\n        text-decoration: none;\n        color: ", ";\n    }\n"])), theme.primary);
var ListContainer = styled.ul(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    display: flex;\n    align-items: left;\n    flex-direction: column;\n"], ["\n    display: flex;\n    align-items: left;\n    flex-direction: column;\n"])));
export var ListItem = function (props) {
    var itemHeader = props.company + " - " + props.title;
    return (_jsxs(Container, { children: [_jsx(TitleContainer, { children: props.url === undefined ? (_jsx(Title, { children: itemHeader }, void 0)) : (_jsx(Title, { children: _jsx("a", __assign({ href: props.url }, { children: itemHeader }), void 0) }, void 0)) }, void 0),
            _jsx(ListContainer, { children: props.points.map(function (t) { return (_jsx("li", { children: t }, void 0)); }) }, void 0)] }, void 0));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
