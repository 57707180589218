import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import ReactDOM from 'react-dom';
import { Header } from './header';
import { Particles } from './particles';
import { Home } from './home';
var App = function () { return (_jsxs("div", { children: [_jsx(Header, {}, void 0),
        _jsx(Particles, {}, void 0),
        _jsx(Home, {}, void 0)] }, void 0)); };
ReactDOM.render(React.createElement(App), document.getElementById('app'));
